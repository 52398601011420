<template>
  <b-container>
    <router-link class="mr-1" to="/pages/coupons">
      <feather-icon class="text-success" icon="ArrowLeftCircleIcon" size="30" />
    </router-link>
    <b-row v-if="!isLoading" class="justify-content-center">
      <b-card class="col-10" title="Create Coupon">
        <b-form @submit.prevent="createCoupon">
          <b-form-group label="Coupon Value:" label-for="couponValue">
            <b-form-input
              v-model="couponValue"
              id="couponValue"
              placeholder="APL2324LR"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Coupon Type:" label-for="couponType">
            <b-form-select
              v-model="couponType"
              id="couponType"
              required
              :options="optionsCouponType"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Expiration Date:" label-for="expirationDate">
            <b-form-datepicker
              required
              id="expirationDate"
              v-model="expirationDate"
            ></b-form-datepicker>
          </b-form-group>
          <b-form-group label="Number Of Users:" label-for="numberOfUsers">
            <b-form-input
              type="number"
              placeholder="10"
              v-model="numberOfUsers"
              id="numberOfUsers"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Number Of Reduction:"
            label-for="numberOfReduction"
          >
            <div class="input-group">
              <b-form-input
                :disabled="couponType === 'payment'"
                :value="couponType === 'payment' ? numberOfReduction = 100 :  ''"
                type="number"
                v-model="numberOfReduction"
                id="numberOfReduction"
                placeholder="50"
                required
              ></b-form-input>
              <div class="input-group-append">
                <feather-icon icon="PercentIcon" size="35" />
              </div>
            </div>
          </b-form-group>
          <b-button type="submit" variant="primary" class="d-block mx-auto">
            <span>
              <i class="ri-coupon-line" style="font-size: 18px"></i> Create
              Coupon</span
            >
          </b-button>
        </b-form>
      </b-card>
    </b-row>
    <LoaderVue v-else />
  </b-container>
</template>

<script>
import {
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BContainer,
  BFormSelect,
  BSpinner,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import LoaderVue from "@/components/Loader/Loader.vue";
export default {
  components: {
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BSpinner,
    BFormDatepicker,
    LoaderVue,
  },
  data() {
    return {
      couponValue: null,
      couponType: "reduction",
      optionsCouponType: [
        { value: "reduction", text: "Reduction" },
        { value: "payment", text: "Payement" },
      ],
      expirationDate: new Date(),
      numberOfUsers: null,
      numberOfReduction: null,
      isLoading: false,
    };
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    createCoupon() {
      const formData = {
        coupon: this.couponValue,
        type_coupon: this.couponType,
        expiration: this.expirationDate,
        number_limit: this.numberOfUsers,
        number_of_reduction: this.numberOfReduction,
      };
      this.isLoading = true;
      axios
        .post("/coupons", formData)
        .then(({ data }) => {
          this.isLoading = false;
          this.showToast("success", "SaveIcon", "Success","Coupon Created Successfully !");
          this.$router.push({ name: "coupons" });
        })
        .catch((error) => {
          this.showToast("danger", "AlertTriangleIcon", "Error", "Something Wrong !");
          this.isLoading = false;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    axios
      .post("/isAdmin")
      .then(({ data }) => {
        if (data.status === true) {
          next(true);
        } else if (data.status === false) {
          next("/error-404");
        }
      })
      .catch((error) => {
        next("/login");
      });
  },
};
</script>

<style scoped></style>
